import { ReactFlowProvider } from 'reactflow';
import * as api from '../../api';
import 'reactflow/dist/style.css';
import './ProcessMap.css';
import { useEffect, useRef } from 'react';
import ProcessMapInner, { ConnectableStep } from './ProcessMapInner';
import { MapPosition } from '../../api/process';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { formatDate } from '../../utils/date-utils';
import Download from '../../components/icon/Download';
import Icon from '../../components/icon/Icon';

interface Props {
  processSteps: api.processes.Step[] & { isCompleted?: boolean };
  activeStepId?: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  onStepSelect?: (stepId: string) => void;
  isEditable?: boolean;
  showExportButton?: boolean;
  processName?: string;
  onStepUpdate?: (id: string, position: MapPosition) => void;
  onPreviousStepAdd?: (source: ConnectableStep, target: ConnectableStep) => void;
  onPreviousStepRemove?: (stepToUpdateId: string, previousStepIdToRemove: string) => void;
  onEdgeSelect?: (edge?: {stepId: string, previousStepId: string}) => void;
}

const ProcessMap: React.FC<Props> = ({
  processSteps,
  parentRef,
  activeStepId,
  onStepSelect,
  isEditable,
  showExportButton,
  processName,
  onStepUpdate,
  onPreviousStepAdd,
  onPreviousStepRemove,
  onEdgeSelect,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentRef && parentRef.current && containerRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      const parentHeight = parentRef.current.offsetHeight;
      containerRef.current.style.width = `${parentWidth - 80}px`;
      containerRef.current.style.height = `${parentHeight - 76}px`;
    }
  }, [containerRef, parentRef]);

  const exportToPdf = async () => {
    if (!containerRef.current) {
      console.error('Container ref not found');
      return;
    };

    try {
      // Get the dimensions of the flow container
      const flowElement = containerRef.current;
      const width = flowElement.offsetWidth;
      const height = flowElement.offsetHeight;

      // Convert the flow to a PNG image
      const imageData = await toPng(flowElement, {
        backgroundColor: '#ffffff',
        width,
        height,
        style: {
          transform: 'scale(1)',
        },
      });

      // Create a new PDF document with extra space for the title
      const pdf = new jsPDF({
        orientation: width > height ? 'landscape' : 'portrait',
        unit: 'px',
        format: [width, height + (processName ? 40 : 0)],
      });

      // Add the process name if provided
      if (processName) {
        pdf.setFontSize(16);
        pdf.text(processName + ' ' + formatDate(new Date()), 20, 30);
      }

      // Add the image below the title
      pdf.addImage(imageData, 'PNG', 0, processName ? 40 : 0, width, height);

      // Save the PDF with process name if available
      pdf.save(`${processName ? processName.toLowerCase().replace(/\s+/g, '-') : 'process-map'}.pdf`);
    } catch (error) {
      console.error('Error exporting to PDF:', error);
    }
  };

  return (
    <ReactFlowProvider>
      <div
        ref={containerRef}
        className="process-map__container"
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        {/* <div ref={flowRef}> */}
          <ProcessMapInner
            processSteps={processSteps}
            activeStepId={activeStepId}
            onStepSelect={onStepSelect}
            isEditable={isEditable}
            onStepUpdate={onStepUpdate}
            onPreviousStepAdd={onPreviousStepAdd}
            onPreviousStepRemove={onPreviousStepRemove}
            onEdgeSelect={onEdgeSelect}
          />
        {/* </div> */}
        {showExportButton && (
          <button 
            onClick={exportToPdf}
            className="link-button"
            style={{ 
              position: 'absolute', 
              bottom: '20px', 
              left: '20px',
              zIndex: 10,
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Icon>
              <Download />
            </Icon>
            <span>Export to PDF</span>
          </button>
        )}
      </div>
    </ReactFlowProvider>
  );
};

export default ProcessMap;
