interface Props {
  className?: string;
}

const InternalControlIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg className={className} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3462 20.0581H22.6817"
        stroke="#BC910C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="4.07129"
        y="3.99512"
        width="24.01"
        height="24.01"
        rx="5"
        stroke="#BC910C"
        stroke-width="1.5"
      />
      <path
        d="M9.34277 19.6925L10.5686 20.797L13.0376 18.5747"
        stroke="#BC910C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3462 13.3886H22.6817"
        stroke="#BC910C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.34277 13.0231L10.5686 14.1275L13.0363 11.9053"
        stroke="#BC910C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default InternalControlIcon;
