import { baseMethods } from '..';

export const calculateDueDate = async (dueInSentence: string) => {
  const res = await baseMethods.post<{ dueDate: { caseDueDate: string, caseDueTime: string } }>({
    path: '/utils/calculate-due-date',
    body: {
      dueInSentence,
    },
  });
  return res;
};
