import { BoltIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { Step } from '../../api/process';
import { ownerGroupToTitleCase } from '../../modules/case/utils';
import InternalControlIcon from '../icon/InternalControl';

interface Props {
  step: Step & {
    current?: boolean;
    isCompleted?: boolean;
  };
}

const StandardStep: React.FC<Props> = ({ step }) => {
  return (
    <div
      className={`step-node ${step.current ? 'step-node--selected' : ''} ${
        step.isCompleted ? 'step-node--completed' : ''
      }`}
    >
      <span className="step-node__text">
        {step.taxonomyLabel && step.taxonomyLabel + ' '}
        {step.name}
      </span>
      <ul className="step-node__owners">
        {step.ownerGroups.map((ownerGroup) => {
          return (
            <li key={ownerGroup} className="step-node__owner">
              {ownerGroupToTitleCase(ownerGroup)}
            </li>
          );
        })}
      </ul>
      <div className="step-node__icons">
        {!!step.automation && <BoltIcon className="step-node__icon" style={{ stroke: 'var(--yellow)' }} />}
        {!!step.isCompleted && <CheckCircleIcon className="step-node__icon" style={{ stroke: 'var(--success)' }} />}
        {step.type === 'CONTROL' && <InternalControlIcon className="step-node__icon" />}
      </div>
    </div>
  );
};

export default StandardStep;
