import isPast from 'date-fns/isPast';
import isThisWeek from 'date-fns/isThisWeek';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

export const formatDate = (date: Date | string, locale?: string, timezone?: string) => {
  const zonedDate = zonedTimeToUtc(new Date(date), timezone || 'UTC');
  let formatString = 'dd/MM/yyyy';
  if (locale === 'en-US') {
    formatString = 'MM/dd/yyyy';
  }
  return formatInTimeZone(zonedDate, timezone || 'UTC', formatString);
};

export const formatDateTime = (date: Date | string, locale: string, timezone: string) => {
  const zonedDate = zonedTimeToUtc(new Date(date), timezone);
  let formatString = 'dd/MM/yyyy HH:mm';
  if (locale === 'en-US') {
    formatString = 'MM/dd/yyyy HH:mm';
  }
  return formatInTimeZone(zonedDate, timezone, formatString);
  // return format(new Date(date), formatString)
};

export const formatDateTimeShort = (date: Date | string, locale: string, timezone: string) => {
  const zonedDate = zonedTimeToUtc(new Date(date), timezone);
  let formatString = 'dd/MM HH:mm';
  if (locale === 'en-US') {
    formatString = 'MM/dd HH:mm';
  }
  return formatInTimeZone(zonedDate, timezone, formatString);
};

export const calculateRagStatus = (date: Date | string) => {
  const isInPast = isPast(new Date(date));
  if (isInPast) {
    return 'red';
  }

  const isInNextWeek = isThisWeek(new Date(date));

  if (isInNextWeek) {
    return 'amber';
  }

  return 'green';
};
