import React, { Fragment, useEffect, useState } from 'react';
import * as api from '../api';
import { jwtLocalStorageKey } from '../api/auth';
import Input from '../components/input/Input';
import { IToast } from '../components/toast/Toast';
import Toasts from '../components/toast/Toasts';
import styles from './LoginPage.module.css';
import { getBrand } from '../utils/brand';
import { trackEvent } from '..';
import PriceCard from '../components/price-card/PriceCard';

interface Props {}

const LoginPage: React.FC<Props> = () => {
  const [mode, setMode] = useState<'LOGIN' | 'SIGN_UP'>('LOGIN');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [toast, setToast] = useState<IToast>({});

  const dummySetToastFn = (newToast: IToast) => {
    setToast(newToast);
    return '';
  };

  const dummyClearToastFn = () => {
    setToast({});
  };

  useEffect(() => {
    api.init(dummySetToastFn, dummyClearToastFn);

    if (window.location.search.includes('sign-up')) {
      setMode('SIGN_UP');
    }
  }, []);

  const handleSendOtpSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (mode === 'LOGIN') {
      await sendLoginOtp();
    } else {
      await handleSignUp();
    }
  };

  const sendLoginOtp = async () => {
    const res = await api.auth.sendOtp(email);

    if (res.error && res.error.code === 'SIGNUP_REQUIRED') {
      setToast({
        header: 'User not found',
        message: 'No user found with the provided email address, please double check and try again',
        type: 'error',
      });
      return;
    }

    if (res.error) {
      setToast({
        header: 'Error',
        message: 'Something went wrong, please try again',
        type: 'error',
      });
      return;
    }

    trackEvent('otpSent');
    setEmailSent(true);
  };

  const handleSignUp = async () => {
    const res = await api.auth.signUp(name, email, companyName);
    if (res.error) {
      setToast({
        header: 'Error',
        message: 'Something went wrong, please try again',
        type: 'error',
      });
      return;
    }

    trackEvent('signUpSuccessful');

    setEmailSent(true);
  };

  const handleVerifyOtpSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const res = await api.auth.verifyOtp(email, otp);

    if (res.data) {
      trackEvent('otpVerified');
      window.localStorage.setItem(jwtLocalStorageKey, res.data.jwt);
      window.location.reload();
    }
  };

  let logoUrl = '/logo512.png';
  let websiteUrl = 'https://er-buddy.com';
  let websiteText = 'ER-Buddy.com';
  if (getBrand() === 'operonix') {
    logoUrl = '/operonix-512.jpg';
    websiteUrl = 'https://operonix.com';
    websiteText = 'Operonix.com';
  }

  const handleSignUpButtonClick = () => {
    setMode(mode === 'LOGIN' ? 'SIGN_UP' : 'LOGIN');
    trackEvent('signUpClicked');
  };

  return (
    <React.Fragment>
      <Toasts toasts={[toast]} clearToast={() => setToast({})} />
      <div className={`content ${styles['login__container']}`}>
        <img src={logoUrl} alt={`${websiteText} logo`} className={styles['login__img']} />
        {/* {mode === 'SIGN_UP' && <PriceCard />} */}

        <div className={`box ${styles['login__inner']}`}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h1 className={styles['login__title']}>{mode === 'LOGIN' ? 'Log In' : 'Sign Up'}</h1>
            {/* <button className="link-button" onClick={handleSignUpButtonClick}>
              {mode === 'LOGIN' ? 'Sign up' : 'Log in'}
            </button> */}
          </div>

          {!emailSent && (
            <form action="" onSubmit={handleSendOtpSubmit}>
              {mode === 'SIGN_UP' && (
                <Fragment>
                  <Input
                    id="company-name"
                    type="text"
                    labelText="Company name"
                    value={companyName}
                    onChange={(val) => setCompanyName(String(val))}
                  />
                  <Input
                    id="name"
                    type="text"
                    labelText="Your name"
                    value={name}
                    onChange={(val) => setName(String(val))}
                  />
                </Fragment>
              )}
              <Input
                id="email"
                type="email"
                labelText="Enter your email address"
                helpText="We will send a secure one-time pass code"
                value={email}
                onChange={(val) => setEmail(String(val))}
              />
              <button className="button" disabled={!email}>
                Send OTP
              </button>
            </form>
          )}
          {!!emailSent && (
            <form onSubmit={handleVerifyOtpSubmit}>
              <Input
                id="otp"
                type="number"
                labelText="Enter the OTP sent to your email address"
                helpText="This is valid for 15 minutes"
                value={otp}
                onChange={(val) => setOtp(String(val))}
              />
              <button className="button" disabled={!otp}>
                Submit
              </button>
            </form>
          )}
        </div>
        <small className={styles['login__help']}>
          Visit <a href={websiteUrl}>{websiteText}</a> for help.
        </small>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
