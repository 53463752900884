import { FormEvent, useEffect, useState } from 'react';
import Input from '../../../../components/input/Input';
import ItemFieldConfigList from '../../configure-item-field/ItemFieldConfigList';
import { Process } from '../../../../api/process';
import InfoBanner from '../../../../components/info-banner/InfoBanner';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

interface Props {
  process: Process
  onSave: (process: Process) => void | Promise<void>;
}

const ProcessItemsConfig: React.FC<Props> = ({ process, onSave }) => {
  const [formData, setFormData] = useState<Process>(process);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSave(formData);
  }

  useEffect(() => {
    setFormData(process);
  }, [process])

  return (
    <form onSubmit={handleSubmit}>
      <h2 style={{ marginBottom: '8px' }}>Case Items</h2>
      {!process.itemFields.length && (
        <InfoBanner
          variant="warning"
          icon={<ExclamationTriangleIcon />}
          message="Case items haven't been configured for this process yet."
        />
      )}
      <p style={{ margin: 0, marginBottom: '16px' }}>
        Items are one or more pieces of data that identify the "thing" that will be the subject of a
        case in this process. For example: an employee(s), a car, a list of inventory items, etc{' '}
      </p>
      <Input
        id="itemName"
        labelText="Item Name"
        value={formData.itemName || ''}
        onChange={(itemName) => setFormData({ ...formData, itemName })}
        helpText='The name of the item(s) that will be the subject of this process. For example, "Employee".'
      />
      <Input
        id="itemDisplayField"
        labelText="Item Display Field"
        value={formData.itemDisplayField || ''}
        onChange={(itemDisplayField) => setFormData({ ...formData, itemDisplayField })}
        helpText='The field of the item from the item fields below that will be displayed in the various case lists. For example: "name".'
      />
      <Input
        id="maxItems"
        labelText="Max Items"
        type="number"
        value={String(formData.maxItems || 1)}
        onChange={(maxItems) => setFormData({ ...formData, maxItems: Number(maxItems) })}
        helpText="The maximum number of items that can be in a case at any time."
      />
      <ItemFieldConfigList
        itemFields={formData.itemFields}
        labelText="Item Fields"
        onChange={(itemFields) => setFormData({ ...formData, itemFields })}
      />
      
      <button className='button'>Save</button>
    </form>
  );
};

export default ProcessItemsConfig;
