import { PropsWithChildren } from 'react'
import './Icon.css'

interface Props {}

const Icon: React.FC<PropsWithChildren<Props>> = ({ children }) => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        className="icon"
        >
            {children}
        </svg>
    )
}

export default Icon
