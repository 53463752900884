import { ItemField } from '../../../api/process'
import './ItemFieldConfigList.css'
import Table, { TableHeader } from '../../../components/table/Table'
import { useState } from 'react'
import { camel } from 'case'
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import ItemFieldConfigForm from './ItemFieldConfigForm'
import Modal from '../../../components/modal/Modal'

interface Props {
  labelText: string
  itemFields: ItemField[]
  onChange: (itemFields: ItemField[]) => void
}

const ItemFieldConfigList: React.FC<Props> = ({ itemFields, labelText, onChange }) => {
  const [selectedItemField, setSelectedItemField] = useState<ItemField>()

  const handleDeleteItemField = (itemField: ItemField) => {
    const updatedItemFields = itemFields.filter((itemFieldFromList) => itemFieldFromList.name !== itemField.name)
    onChange(updatedItemFields)
  }

  const handleAddItemField = (newItemField: ItemField) => {
    const updatedItemFields = [
      ...itemFields,
      {
        ...newItemField,
        name: camel(newItemField.name),
        requiredOnCreate: newItemField.requiredOnCreate,
        minLength: newItemField.minLength || undefined,
        maxLength: newItemField.maxLength || undefined,
      },
    ]
    onChange(updatedItemFields)
  }

  const handleUpdateItemField = (updatedItemField: ItemField, oldItemFieldName?: string) => {
    
    const updatedItemFields = itemFields.map((itemField) => {
      if (itemField.name === oldItemFieldName) {
        return updatedItemField
      }
      return itemField
    })

    onChange(updatedItemFields);
    setSelectedItemField(undefined);
  }
  

  const tableHeaders: TableHeader<ItemField>[] = [
    'name',
    'type',
    'requiredOnCreate',
    (itemField) => {
      return {
        header: '',
        element: (
          <div>
            <button className="icon-button" type="button" onClick={() => handleDeleteItemField(itemField!)}>
              <TrashIcon />
            </button>

            <button className="icon-button" type='button' onClick={() => setSelectedItemField(itemField!)}>
              <PencilIcon />
            </button>
          </div>
        ),
      }
    },
  ]

  return (
    <div className="item-field-config">
      <p className="item-field-config__title">{labelText}</p>

      <Table data={itemFields} headers={tableHeaders} emptyMessage="No item fields configured" />
      <div style={{ padding: '32px', marginTop: '16px' }}>
        <ItemFieldConfigForm
          onSubmit={handleAddItemField}
        />
      </div>
      
      <Modal
        isOpen={!!selectedItemField}
        handleClose={() => setSelectedItemField(undefined)}>
        <ItemFieldConfigForm
          existingItemField={selectedItemField}
          onSubmit={handleUpdateItemField}
          />
        </Modal>
    </div>
  )
}

export default ItemFieldConfigList
