import { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as api from '../../api';
import { Process, Step } from '../../api/process';
import ProcessMap from '../../modules/process-map/ProcessMap';
import './ProcessAdmin.css';
import Table, { HeaderComponent } from '../../components/table/Table';
import CsvDownload from '../../components/csv-download/CsvDownload';
import CsvUpload from '../../components/csv-upload/CsvUpload';
import ProcessStepForm from '../../modules/admin/process-admin/ProcessStepForm';
import FileUpload from '../../components/file-upload/FileUpload';
import Input from '../../components/input/Input';

interface Props {}

const ProcessAdmin: React.FC<Props> = () => {
  const previewRef = useRef<HTMLDivElement | null>(null);
  const selectedStepRef = useRef<HTMLDivElement | null>(null);
  const { id } = useParams();
  const [process, setProcess] = useState<Process | null>(null);
  const [processSteps, setProcessSteps] = useState<Step[]>([]);
  const [selectedStep, setSelectedStep] = useState<Step | null>(null);

  const getProcess = async () => {
    const res = await api.processes.admin.get(id as string);
    if (res.data) {
      setProcess(res.data);
    }

    const stepsRes = await api.processes.admin.listSteps(id as string);
    if (stepsRes.data) {
      setProcessSteps(stepsRes.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getProcess();
    });
  }, [id]);

  const handleStepsUpload = async (steps: any[]) => {
    const cleanedSteps = steps.map((step) => {
      const cleanObject = {
        ...step,
        ownerGroups: step.ownerGroups.split(','),
        stepNumber: Number(step.stepNumber),
        track: Number(step.track),
        processId: id,
        companyId: undefined,
        autoComplete: step.autoComplete === 'true',
        previousStepIds: step.previousStepIds ? step.previousStepIds.split(',') : [],
        supportingData: undefined,
        dueInDays: step.dueInDays ? Number(step.dueInDays) : undefined,
      };

      Object.keys(cleanObject).forEach((key) => {
        if (cleanObject[key] === '') {
          cleanObject[key] = undefined;
        }
      });

      return cleanObject;
    });
    const res = await api.processes.admin.bulkUpsertSteps(id as string, cleanedSteps);
    if (res.data) {
      setProcessSteps(res.data);
    }
  };

  const stepIdColumn: HeaderComponent<Step> = (step) => {
    if (!step) return { header: 'ID', element: <Fragment></Fragment> };
    return {
      header: 'ID',
      element: (
        <button
          className="link-button"
          onClick={() => {
            setSelectedStep(step);
            setTimeout(() => {
              if (selectedStepRef.current) {
                selectedStepRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }, 10);
          }}
        >
          {step.id.slice(0, 6)}...
        </button>
      ),
    };
  };

  const stepColumns = [
    stepIdColumn,
    ...Object.keys(processSteps[0] || {}).filter((key) => key !== 'id'),
  ];

  const handleStepSelectedOnMap = (stepId: string) => {
    const step = processSteps.find((step) => step.id === stepId);
    if (step) {
      setSelectedStep(step);
    }
  };

  return (
    <main className="content">
      <div className="box">
        <h2>Process Admin</h2>
        {!!process && (
          <Fragment>
            <h1>{process.name}</h1>
          </Fragment>
        )}

        {!processSteps.length && (
          <Fragment>
            <p>No steps found, copy and paste your process document here and we will generate the steps for you</p>
            <Input
              id='process-document-text'
              labelText='Process Document'
              value=''
              onChange={() => {}}
              type="textarea"
            />

            {/* <CsvUpload labelText='Bulk Upload Steps' onParseComplete={handleStepsUpload} /> */}
          </Fragment>
        )}
        {!!processSteps.length && (
          <Fragment>
            <div className="process-admin__csv-actions">
              <CsvDownload
                data={processSteps}
                exportName={`${process?.name || 'process'}-steps.csv`}
              />
              <CsvUpload labelText="Bulk Upload Steps" onParseComplete={handleStepsUpload} />
            </div>

            <Table
              data={processSteps}
              maxHeight={selectedStep ? '250px' : '500px'}
              headers={stepColumns}
              search={true}
            />

            {!!(selectedStep && process) && (
              <div className="process-admin__selected-step" ref={selectedStepRef}>
                <h3>
                  Selected step: ({selectedStep.id.slice(0, 6)}...) {selectedStep.name}
                </h3>
                <ProcessStepForm
                  stepToUpdate={selectedStep}
                  allSteps={processSteps}
                  updateAllSteps={setProcessSteps}
                  process={process}
                />
              </div>
            )}
            <div ref={previewRef} className="process-admin__preview">
              <ProcessMap
                processSteps={processSteps}
                parentRef={previewRef}
                activeStepId={selectedStep?.id}
                onStepSelect={handleStepSelectedOnMap}
                showExportButton={true}
                processName={process?.name}
              />
            </div>
          </Fragment>
        )}
      </div>
    </main>
  );
};

export default ProcessAdmin;
