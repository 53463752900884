import { useParams } from "react-router-dom"
import * as api from "../api"
import { Fragment, useEffect, useRef, useState } from "react"
import ViewProcessSideBar from "../modules/ViewProcessSideBar"
import ProcessMap from "../modules/process-map/ProcessMap"
import './ViewProcessPage.css'

interface Props {}

const ViewProcessPage: React.FC<Props> = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [process, setProcess] = useState<api.processes.Process | null>(null)

  const params = useParams()

  const fetchProcess = async () => {
    const res = await api.processes.get(params.id!)
    
    if (res.data) {
      setProcess(res.data)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      fetchProcess()
    })
    
  }, [params.id])
  return (
    <main>
      {process && (
        <Fragment>
          <div className="view-process-container">
            <ViewProcessSideBar process={process} />
            <ProcessMap 
              processSteps={process.steps} 
              parentRef={containerRef} 
              showExportButton={true}
              processName={process.name}
            />
          </div>
          
        </Fragment>
      )}
    </main>
  )
}

export default ViewProcessPage