import { Fragment, useEffect, useState } from 'react';
import { Process, Step } from '../../../../api/process';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/input/Select';
import { PencilIcon } from '@heroicons/react/24/outline';
import './EditStep.css';
import Modal from '../../../../components/modal/Modal';
import EditGuidanceNotes from './EditGuidanceNotes';
import EditStepData from './EditStepData';
import ConfigureAutomation from '../../configura-automation/ConfigureAutomation';
import TagSelect from '../../../../components/input/TagSelect';
import EditDueDate from './EditDueDate';

interface Props {
  process: Process;
  selectedStepId: string | undefined;
  allSteps: Step[];
  handleStepUpdate: (updatedStep: Step) => void;
}

type EditableData = Pick<Step, 'name' | 'type' | 'ownerGroups'>;

const EditStep: React.FC<Props> = ({ process, selectedStepId, allSteps, handleStepUpdate }) => {
  const [editableData, setEditableData] = useState<EditableData>({
    name: '',
    type: 'MANUAL',
    ownerGroups: [],
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<
    'GUIDANCE_NOTES' | 'STEP_DATA' | 'AUTOMATION' | 'DUE_DATE' | undefined
  >();

  const selectedStep = allSteps.find((step) => step.id === selectedStepId);

  useEffect(() => {
    const selectedStep = allSteps.find((step) => step.id === selectedStepId);
    if (selectedStep) {
      setEditableData({
        name: selectedStep.name,
        type: selectedStep.type,
        ownerGroups: selectedStep.ownerGroups,
      });
    }
  }, [selectedStepId]);

  const handleSave = () => {
    handleStepUpdate({
      ...selectedStep,
      ...editableData,
    } as Step);
    setIsEditing(false);
  };

  return (
    <Fragment>
      <div className="edit-step__header-container">
        <h3>Edit step</h3>
        <button className="icon-button" onClick={() => setIsEditing(!isEditing)}>
          <PencilIcon />
        </button>
      </div>

      {!!selectedStep && (
        <Fragment>
          <Input
            id="step-name"
            labelText="Name"
            value={editableData.name}
            onChange={(name) => setEditableData({ ...editableData, name })}
            readOnly={!isEditing}
          />
          <TagSelect
            availableTags={process.ownerGroups}
            selectedTags={editableData.ownerGroups}
            labelText="User roles"
            helpText="Only users in the user roles selected can complete this step"
            onChange={(ownerGroups) => setEditableData({ ...editableData, ownerGroups })}
            readOnly={!isEditing}
          />
          <Select
            id="step-type"
            labelText="Type"
            value={editableData.type}
            onChange={(type) => setEditableData({ ...editableData, type })}
            options={[
              { label: 'Step', value: 'MANUAL' },
              { label: 'Decision', value: 'DECISION' },
              { label: 'Control', value: 'CONTROL' },
            ]}
            readOnly={!isEditing}
          />
          {!!isEditing && (
            <button className="button button--small" onClick={handleSave}>
              Save
            </button>
          )}
          <div className="edit-step__options">
            <button className="box-button" onClick={() => setSelectedOption('GUIDANCE_NOTES')}>
              Add Work Instructions
            </button>
            <button className="box-button" onClick={() => setSelectedOption('STEP_DATA')}>
              Edit data to be collected at this step
            </button>
            <button className="box-button" onClick={() => setSelectedOption('AUTOMATION')}>
              Set up an automation
            </button>
            <button className="box-button" onClick={() => setSelectedOption('DUE_DATE')}>
              Set a due date when this step is started
            </button>
          </div>
          <Modal
            isOpen={!!selectedOption}
            handleClose={() => setSelectedOption(undefined)}
            modalStyles={{ minWidth: '90vw', minHeight: '50vh' }}
          >
            {selectedOption === 'GUIDANCE_NOTES' && (
              <EditGuidanceNotes
                guidanceNotes={selectedStep.guidanceNotes || ''}
                handleSave={(guidanceNotes) => {
                  handleStepUpdate({
                    ...selectedStep,
                    guidanceNotes,
                  } as Step);
                  setSelectedOption(undefined);
                }}
                entityType="CASE_STEP"
                entityId={selectedStepId!}
              />
            )}
            {selectedOption === 'STEP_DATA' && (
              <EditStepData
                supportingData={selectedStep.supportingData || []}
                onSave={(supportingData) => {
                  handleStepUpdate({
                    ...selectedStep,
                    supportingData,
                  } as Step);
                  setSelectedOption(undefined);
                }}
              />
            )}
            {selectedOption === 'AUTOMATION' && (
              <ConfigureAutomation
                allSteps={allSteps}
                process={process}
                stepId={selectedStepId!}
                stepAutomation={selectedStep.automation}
                onChange={(automation) => {
                  handleStepUpdate({
                    ...selectedStep,
                    automation,
                  } as Step);
                }}
                onSave={() => setSelectedOption(undefined)}
              />
            )}

            {selectedOption === 'DUE_DATE' && (
              <EditDueDate
                updateDueInSentence={(dueInSentence) =>
                  handleStepUpdate({ ...selectedStep, dueInSentence })
                }
                dueInSentence={selectedStep.dueInSentence}
                onSave={() => setSelectedOption(undefined)}
              />
            )}
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditStep;
