import { baseMethods } from '.';

// TODO: update this
export interface UploadedFile {
  id: string;
  createdAt: Date;
  url: string;
  name: string;
  entityType: 'CASE' | 'CASE_STEP' | 'LETTER_TEMPLATE' | 'IMAGE';
  entityId: string;
}

export const upload = async (formData: FormData, entityId: string, entityType: UploadedFile['entityType'], name?: string) => {
  if (name) {
    formData.append('name', name);
  }

  return baseMethods.post<UploadedFile>({
    path: `/${entityType.toLowerCase()}/${entityId}/file/upload`,
    body: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    errorMessage: (statusCode, apiCode) => {
      if (apiCode === 'INVALID_FILE_EXTENSION') {
        return {
          header: 'Invalid File Type',
          message: `Please provide one of the following file types: 'png','jpg','pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'jpeg'`,
        };
      }
    },
  });
};

export const download = async (entityId: string, entityType: UploadedFile['entityType'], fileId: string) => {
  return baseMethods.get({
    path: `/${entityType}/${entityId}/file/${fileId}/download`,
    responseType: 'blob',
  });
};

export const list = async (entityId: string, entityType: UploadedFile['entityType']) => {
  return baseMethods.get<UploadedFile[]>({
    path: `/${entityType.toLowerCase()}/${entityId}/files`,
  });
}

export const get = async (fileId: string) => {
  return baseMethods.get<UploadedFile>({
    path: `/file/${fileId}`,
  });
}
