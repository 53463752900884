import { useState } from 'react'
import Input from '../../../components/input/Input'
import Select from '../../../components/input/Select'
import TagInput from '../../../components/input/TagInput'
import { ItemField } from '../../../api/process'
import { camel } from 'case'
import './ItemFieldConfigList.css'

interface Props {
  onSubmit: (itemField: ItemField, oldItemFieldName?: string) => void
  existingItemField?: ItemField
}

const fieldTypeOptions = [
  { value: 'text', label: 'Text' },
  { value: 'select', label: 'Select' },
  { value: 'textarea', label: 'Large text box' },
  { value: 'checkbox', label: 'Checkbox' },
  { value: 'currency', label: 'Currency' },
  { value: 'number', label: 'Number' },
  { value: 'date', label: 'Date' },
  { value: 'file', label: 'File' },
  { value: 'formula', label: 'Formula' },
]

const emptyItemField: ItemField = { name: '', type: 'text', requiredOnCreate: true, options: [] }

const ItemFieldConfigForm: React.FC<Props> = ({ onSubmit, existingItemField }) => {
  const [newItemField, setNewItemField] = useState<ItemField>(existingItemField || emptyItemField)

  return (
    <div className="item-field-config__new">
      <p className="item-field-config__title">{existingItemField ? 'Edit field' : 'Add a new field'}</p>
      <div className="item-field-config__inputs">
        <Input
          id="new-item-field-name"
          labelText="Label"
          value={newItemField.label || ''}
          onChange={(label) => setNewItemField({ ...newItemField, label })}
          containerClassName="item-field-config__name-input"
        />
        <Input
          id="new-item-field-name"
          labelText="Name"
          value={newItemField.name}
          onChange={(name) => setNewItemField({ ...newItemField, name })}
          helpText={`Will be saved as ${camel(newItemField.name)}`}
          containerClassName="item-field-config__name-input"
        />
        <Select
          id="new-item-field-type"
          labelText="Type"
          options={fieldTypeOptions}
          value={newItemField.type}
          onChange={(type) => setNewItemField({ ...newItemField, type: type as ItemField['type'] })}
          containerClassName="item-field-config__type-input"
        />
      </div>
      {newItemField.type === 'select' && (
        <div className="item-field-config__inputs">
          <TagInput
            id="options"
            labelText="Options*"
            value={newItemField.options?.map((option) => option.value) as unknown as string}
            onChange={(options) =>
              setNewItemField({
                ...newItemField,
                options: (options as unknown as string[]).map((option) => {
                  return { value: option, label: option }
                }),
              })
            }
            small={true}
            emptyMessage="Please specify at least 1 option"
          />
        </div>
      )}
      {newItemField.type === 'formula' && (
        <Input
          id="new-item-field-formula"
          labelText="Formula"
          value={newItemField.formula || ''}
          onChange={(formula) => setNewItemField({ ...newItemField, formula })}
          helpText={`e.g. CONCATENATE({{name}},{{employeeNumber}})`}
          containerClassName="item-field-config__name-input"
        />
      )}
      {(newItemField.type === 'text' || newItemField.type === 'number') && (
        <div className="item-field-config__bottom-inputs" style={{ flexDirection: 'row' }}>
          <Input
            id="new-item-field-formula"
            labelText="Min Length"
            type="number"
            value={String(newItemField.minLength)}
            onChange={(minLength) => setNewItemField({ ...newItemField, minLength: Number(minLength) })}
            containerClassName="item-field-config__name-input"
          />
          <Input
            id="new-item-field-formula"
            labelText="Max Length"
            type="number"
            value={String(newItemField.maxLength)}
            onChange={(maxLength) => setNewItemField({ ...newItemField, maxLength: Number(maxLength) })}
            containerClassName="item-field-config__name-input"
          />
        </div>
      )}

      <div className="item-field-config__bottom-inputs">
        <Input
          id="new-item-field-required"
          labelText="Required"
          type="checkbox"
          value={newItemField.requiredOnCreate ? 'true' : 'false'}
          onChange={(requiredOnCreate) =>
            setNewItemField({ ...newItemField, requiredOnCreate: requiredOnCreate === 'true' })
          }
          containerClassName="item-field-config__required-checkbox"
        />
        <button
          className="button button--small button--secondary"
          type="button"
          disabled={
            !newItemField.name ||
            !newItemField.type ||
            (newItemField.type === 'select' && !newItemField.options?.length)
          }
          onClick={() => {
            onSubmit(newItemField, existingItemField?.name)
            setNewItemField(emptyItemField)
          }}
        >
          {!!existingItemField ? 'Save' : 'Add new field'}
        </button>
      </div>
    </div>
  )
}

export default ItemFieldConfigForm
